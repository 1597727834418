import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const EmptyCartRoot = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 350px;
  justify-content: center;
`

const SadCartCopy = styled.div`
  color: blue;
  margin-top: 20px;
  max-width: 250px;
  text-align: center;
  p {
    margin: 0;
  }
`

const SadCart = () => (
  <svg
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.682 42.188C18.236 42.188 15.83 45.244 15.746 48.1C15.746 51.49 18.439 54.18 21.746 54.18C25.054 54.18 27.744 51.49 27.744 48.184C27.7421 46.5938 27.1094 45.0693 25.9847 43.9452C24.86 42.821 23.3352 42.189 21.745 42.188H21.682ZM21.682 45.188H21.745C22.5397 45.188 23.3019 45.5037 23.8638 46.0656C24.4258 46.6276 24.7415 47.3898 24.7415 48.1845C24.7415 48.9792 24.4258 49.7414 23.8638 50.3033C23.3019 50.8653 22.5397 51.181 21.745 51.181C20.089 51.181 18.745 49.84 18.745 48.184C18.781 46.93 19.918 45.188 21.682 45.188ZM48.68 42.188C45.233 42.188 42.827 45.244 42.743 48.1C42.743 51.49 45.437 54.18 48.743 54.18C52.051 54.18 54.742 51.49 54.742 48.184C54.7402 46.5936 54.1073 45.069 52.9824 43.9448C51.8574 42.8206 50.3324 42.1888 48.742 42.188H48.68ZM48.68 45.188H48.742C49.5367 45.188 50.2989 45.5037 50.8608 46.0656C51.4228 46.6276 51.7385 47.3898 51.7385 48.1845C51.7385 48.9792 51.4228 49.7414 50.8608 50.3033C50.2989 50.8653 49.5367 51.181 48.742 51.181C47.086 51.181 45.742 49.84 45.742 48.184C45.778 46.93 46.915 45.187 48.679 45.187L48.68 45.188Z"
      fill="#2B3DDE"
    />
    <path
      d="M53.994 39.496H20.998C17.998 39.496 14.998 36.496 14.998 33.496C14.998 30.496 17.998 27.497 20.998 27.497"
      stroke="#2B3DDE"
      stroke-width="2"
    />
    <path
      d="M50.037 27.372L54.452 8H11.542L15.958 27.372C16.1156 27.8454 16.4183 28.2572 16.823 28.549C17.2277 28.8409 17.714 28.9979 18.213 28.998H47.782C48.2811 28.9982 48.7677 28.8412 49.1726 28.5493C49.5775 28.2575 49.8803 27.8456 50.038 27.372H50.037Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M11.542 8L10.454 3.809C10.3207 3.29131 10.019 2.83264 9.59651 2.50516C9.17399 2.17768 8.65458 1.99997 8.12 2H0M11.542 8H54.452L50.037 27.37C49.8793 27.8436 49.5765 28.2555 49.1716 28.5473C48.7667 28.8392 48.2801 28.9962 47.781 28.996H18.213C17.7139 28.9962 17.2273 28.8392 16.8224 28.5473C16.4175 28.2555 16.1147 27.8436 15.957 27.37L11.542 8Z"
      stroke="#2B3DDE"
      stroke-width="2"
    />
    <circle cx="29" cy="13" r="2" fill="#2B3DDE" />
    <circle cx="38" cy="13" r="2" fill="#2B3DDE" />
    <line
      x1="24.2007"
      y1="17.85"
      x2="43.1456"
      y2="21.1803"
      stroke="#2B3DDE"
      stroke-width="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="23.511"
        y1="25.156"
        x2="23.511"
        y2="10.5576"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.0001" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

const EmptyCart = () => (
  <EmptyCartRoot>
    <SadCart />
    <SadCartCopy>
      <p>Your Cart is empty. </p>

      <Link to-="/">Return to Shopping!</Link>
    </SadCartCopy>
  </EmptyCartRoot>
)

export default EmptyCart
