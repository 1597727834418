// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:72c93824-0c40-4ea1-b0db-a93fcb1bd605",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_8gtLeRAzr",
    "aws_user_pools_web_client_id": "3ui7c1rpkgbcmmv07r9r236nuv",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://wqol6gerzvgn3h7bldwsnhse2i.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
